
// @ is an alias to /src
import FlatButton from "@/components/atomics/FlatButton.vue";
import FileTabs from "@/components/atomics/file-tabs/FileTabs.vue";
import FileTab from "@/components/atomics/file-tabs/FileTab.vue";
import { defineComponent, ref } from "vue";
import { RepositoryFactory, AuthRepository } from "@/lib/https";
import { key, storage } from "@/lib/storage";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ContractTerms",
  components: {
    FlatButton,
    FileTabs,
    FileTab,
  },
  setup(props) {
    const termsAgreed = ref(false);
    const { t } = useI18n();

    const list = ref([
      {
        name: t("sign.termsOfUse"),
        content: "/term-converted.html",
      },
      {
        name: t("sign.privacyPolicy"),
        content: "/privacy-converted-filter.htm",
      },
    ]);

    const localStorage = storage.getLocalStorage();
    const guestCode = localStorage.get("guestCode");
    const router = useRouter();

    const { loginGuest } =
      RepositoryFactory.getRepository<AuthRepository>(AuthRepository);

    const submitTerm = async () => {
      const guestData = new FormData();
      guestData.append("guestCode", guestCode);

      const response = await loginGuest(guestData).catch((e) => {
        if (e.data.code === "E0001") {
          router.push("/sign/access-code");
        } else {
          router.push({
            name: "ErrorPage",
            query: {
              error: e.data.message || "",
            },
          });
        }
      });

      if (response && response.sessionId) {
        localStorage.add(key.sessionId, response.sessionId);
        localStorage.add("userType", response.type);
        router.push(`/guest/contract-guest?contractId=${response.contractId}`);
      }
    };

    const onLoaded = () => {
      const frame = document.querySelector(".frame-content") as any;
      frame.contentDocument.body.style.overflowX = "hidden";
    };

    return { termsAgreed, submitTerm, list, onLoaded };
  },
});
